
import { computed, defineComponent, ref, watch } from 'vue'

import AppChip from '@common/AppChip.vue'
import AppTextEditor from '@/components/common/AppTextEditor.vue'

import { MIN_CHARS_INCLUDING_HTML_START_END_BRACKETS } from '@/types/global'

export default defineComponent({
  name: 'GoalsSuccessCriteria',
  emits: ['update:modelValue', 'save-criteria', 'close-editor'],
  components: {
    AppChip,
    AppTextEditor
  },
  props: {
    modelValue: {
      type: String,
      required: false
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const displayEditor = ref(false)
    const displayCriteriaHTML = computed(() => !displayEditor.value && isCriteriaValid.value && props.isEditing)
    const isCriteriaValid = computed(() => criteriaHTML.value && criteriaHTML.value.length >= MIN_CHARS_INCLUDING_HTML_START_END_BRACKETS)
    const isChipActive = computed(() => !isCriteriaValid.value && !displayEditor.value)
    const hasEditButtons = computed(() => displayEditor.value && props.isEditing)
    const criteriaHTML = computed({
      get: () => props.modelValue,
      set: (value :string | undefined) => emit('update:modelValue', value)
    })
    const loader = computed(() => props.loading)
    const isImageProcessing = ref(false)

    function resetEdits() {
      displayEditor.value = false
      criteriaHTML.value = ''
    }

    function handleCancel() {
      displayEditor.value = false
      emit('close-editor')
    }

    watch(loader, (_, oldValue) => {
      if (oldValue) { // loader is on
        displayEditor.value = false
      }
    })

    return {
      criteriaHTML,
      displayEditor,
      displayCriteriaHTML,
      isCriteriaValid,
      isChipActive,
      hasEditButtons,
      resetEdits,
      handleCancel,
      isImageProcessing
    }
  }
})
