
import { defineComponent, ref } from 'vue'

import ToastNotificationService from '@/services/ToastNotificationService'
import ModalHandlerService from '@/services/ModalHandlerService'

import { userProfiles } from '@/data/design-system'
import { SUPPORTED_GOAL_STATUSES, SUPPORTED_OBJECTIVE_STATUSES } from '@/data/objectives'

import { IToggleOption, CHECK_IN_SECTIONS } from '@/types/global'

import AppButtonMini from '@common/AppButtonMini.vue'
import AppTooltip from '@common/AppTooltip.vue'
import AppSpacer from '@common/AppSpacer.vue'
import AppLogo from '@common/AppLogo.vue'
import AppChip from '@common/AppChip.vue'
import AppCalendar from '@common/AppCalendar.vue'
import AppToggleButton from '@common/AppToggleButton.vue'
import AppPercentageSlider from '@common/AppPercentageSlider.vue'
import AppSwitch from '@global/AppSwitch.vue'

import CheckInTextarea from '@feature/check-in/CheckInTextarea.vue'
import GoalsStatusChips from '@feature/goals/GoalsStatusChips.vue'
import DesignSystemItem from '@/components/DesignSystemItem.vue'
import GoalsStatusDropdown from '@feature/goals/GoalsStatusDropdown.vue'
import GoalsSuccessCriteria from '@/components/feature/goals/GoalsSuccessCriteria.vue'

import HomeDonutChart from '@/components/feature/home/HomeDonutChart.vue'
import HomeGaugeChart from '@/components/feature/home/HomeGaugeChart.vue'

export default defineComponent({
  name: 'DesignSystem',
  components: {
    AppLogo,
    AppChip,
    AppSpacer,
    AppSwitch,
    AppTooltip,
    AppButtonMini,
    AppToggleButton,
    AppCalendar,
    AppPercentageSlider,
    CheckInTextarea,
    GoalsStatusChips,
    GoalsStatusDropdown,
    GoalsSuccessCriteria,
    HomeDonutChart,
    DesignSystemItem,
    HomeGaugeChart
  },
  setup() {
    const icons = require.context('@/assets/icons', false, /.*\.svg$/)

    /** Component specific states */
    const quizSelectableButtonActive = ref('')
    const activeProfile = ref(userProfiles[0])
    const activeOption = ref(CHECK_IN_SECTIONS.CYCLE_VIEW)
    const criteriaText = ref('<p>test</p>')

    const status = ref()
    const userSyncStatus = {
      emoji: '😡',
      title: 'lorem'
    }

    const selectedMood = ref({})
    const selectedGoalStatus = ref({})
    const selectedBlockerStatus = ref({})
    const range = ref(0)

    const date = ref()

    const updateStatus = (value: string) => {
      if (status.value === value) {
        status.value = ''
      } else {
        status.value = value
      }
    }

    const TOGGLE_OPTIONS :IToggleOption[] = [
      {
        text: CHECK_IN_SECTIONS.CYCLE_VIEW,
        iconName: 'pulse'
      },
      {
        text: CHECK_IN_SECTIONS.ACTION_LIST,
        iconName: 'action-list-mobile'
      }
    ]

    function setActiveOption(option: CHECK_IN_SECTIONS) {
      activeOption.value = option
    }

    return {
      ToastNotificationService,
      placeholderText: 'The quick brown fox jumps over the lazy dog',
      shortPlaceholderText: 'Lorem ipsum',
      icons: icons.keys().map((iconPath: string) => iconPath && iconPath.split('/').pop()?.replace('.svg', '')),

      /** Component specific states */
      quizSelectableButtonActive,
      userProfiles,
      ModalHandlerService,
      activeProfile,
      TOGGLE_OPTIONS,
      criteriaText,
      setActiveOption,
      updateStatus,
      status,
      date,
      range,
      selectedMood,
      selectedGoalStatus,
      selectedBlockerStatus,
      userSyncStatus,
      SUPPORTED_GOAL_STATUSES,
      SUPPORTED_OBJECTIVE_STATUSES
    }
  }
})
