
import { useStore } from '@/store'
import { Membership } from '@/types/membership'
import { CommentType } from '@/types/comments'
import Reaction from '@/types/reaction'
import { SocialConnect, VoteContent } from '@/types/socialConnect'
import { User } from '@/types/user'
import { timeDiffCalc } from '@/utils/calculateTimeDiff'
import AppAvatar from '@common/AppAvatar.vue'
import ReactionDisplay from '@common/ReactionDisplay.vue'
import ReactionInput from '@common/ReactionInput.vue'
import QuizSelectableButton from '@feature/quiz/QuizSelectableButton.vue'
import Conversation from '@common/Conversation.vue'
import { DateTime } from 'luxon'
import { computed, defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'SocialConnectCard',
  components: {
    QuizSelectableButton,
    AppAvatar,
    ReactionDisplay,
    ReactionInput,
    Conversation
  },
  props: {
    socialConnect: {
      type: Object as PropType<SocialConnect>,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const user = computed<User>(() => store.getters['user/getUser'])
    const teamMemberships = computed<Membership[]>(() => store.getters['teams/getActiveTeamMembers'])
    const currentUserReaction = ref(props.socialConnect.reactions?.find(e => e.userId === user.value.userId)?.id)
    const conversationComponent = ref()
    const enableCommentfield = ref(false)

    async function submitReaction() {
      const reactions = (props.socialConnect.reactions ?? []).filter(e => e.userId !== user.value.userId)
      if (currentUserReaction.value) {
        reactions.push(new Reaction(currentUserReaction.value, user.value.userId, DateTime.utc().toISO()))
      }
      await store.dispatch('socialConnect/updateSocialConnect', { current: props.socialConnect, updates: { reactions, lastUpdatedAt: props.socialConnect.lastUpdatedAt } })
    }

    function scrollToConversationInput() {
      enableCommentfield.value = true
      setTimeout(() => {
        conversationComponent.value.newCommentEditor.$el.scrollIntoView({ behavior: 'smooth', block: 'center' }) // scroll to the reply editor when it is opened
        conversationComponent.value.newCommentEditor.editor.chain().focus()
        enableCommentfield.value = false
      }, 100)
    }

    async function voteTeamSplits(voteForOptionA: boolean) {
      const content = props.socialConnect.content as VoteContent
      const userId = user.value.userId
      content.optionAVotes = voteForOptionA ? [...new Set([...content.optionAVotes, userId])] : content.optionAVotes.filter(e => e !== userId)
      content.optionBVotes = voteForOptionA ? content.optionBVotes.filter(e => e !== userId) : [...new Set([...content.optionBVotes, userId])]
      await store.dispatch('socialConnect/updateSocialConnect', { current: props.socialConnect, updates: { content } })
    }

    function getMembersByTeamSplitVote(votedForA: boolean, votedForB: boolean) {
      const content = props.socialConnect.content as VoteContent
      const membersWhoVotedA = teamMemberships.value.filter(e => content.optionAVotes.includes(e.userId))
      const membersWhoVotedB = teamMemberships.value.filter(e => content.optionBVotes.includes(e.userId))
      if (votedForA) {
        return membersWhoVotedA
      } else if (votedForB) {
        return membersWhoVotedB
      } else {
        return teamMemberships.value.filter(e => !membersWhoVotedA.includes(e) && !membersWhoVotedB.includes(e))
      }
    }

    return {
      user,
      currentUserReaction,
      submitReaction,
      conversationComponent,
      scrollToConversationInput,
      enableCommentfield,
      getMembersByTeamSplitVote,
      voteTeamSplits,
      timeDiffCalc,
      CommentType
    }
  }
})
