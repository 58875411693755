
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'AppPercentageSlider',
  props: {
    modelValue: {
      type: [Number, String],
      required: true
    }
  },
  setup(props, { emit }) {
    const range = computed({
      get: () => props.modelValue,
      set: (value: number | string) => emit('update:modelValue', value)
    })

    const thumbDifference = computed(() => Number(range.value) / 3.33)
    const calculateProgress = computed(() => {
      if (range.value > 100) {
        return 'calc(100% - 30px)'
      }

      return `calc(${range.value}% - ${thumbDifference.value}px)`
    })

    function handlePercentage() {
      const withoutLeadingZero = ref(parseInt(range.value as string, 10))
      range.value = withoutLeadingZero.value

      if (range.value > 100) {
        range.value = 100
      } else if (range.value <= 0) {
        range.value = 0
      }
    }

    return {
      range,
      calculateProgress,
      handlePercentage
    }
  }
})
