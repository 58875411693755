import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["gauge-wrapper", [ _ctx.theme && `gauge-wrapper--${_ctx.theme}` ]]),
    style: _normalizeStyle([
      _ctx.size && `--size: ${_ctx.size}px;`,
      _ctx.thickness && `--thickness: ${_ctx.thickness}px;`
    ])
  }, [
    _createElementVNode("div", {
      class: "gauge-wrapper__arc",
      "data-value": _ctx.value
    }, null, 8, _hoisted_1)
  ], 6))
}