
import { defineComponent, PropType } from 'vue'

export enum Themes {
  RED = 'red',
  YELLOW = 'yellow',
  GREEN = 'green'
}

export default defineComponent({
  name: 'HomeGaugeChart',
  props: {
    value: {
      type: Number,
      required: true
    },
    size: {
      type: Number,
      required: false
    },
    thickness: {
      type: Number,
      required: false
    },
    theme: {
      type: String as PropType<Themes>,
      required: false,
      validator: (value: Themes): boolean => Object.values(Themes).includes(value)
    }
  }
})
