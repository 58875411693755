
import { defineComponent, PropType } from 'vue'

enum SUPPORTED_CHART_THEMES {
  ORANGE = 'orange',
  PURPLE = 'purple'
}

export default defineComponent({
  name: 'HomeDonutChart',
  props: {
    value: {
      type: Number,
      required: true
    },
    size: {
      type: Number,
      required: false
    },
    thickness: {
      type: Number,
      required: false
    },
    theme: {
      type: String as PropType<SUPPORTED_CHART_THEMES>,
      default: SUPPORTED_CHART_THEMES.ORANGE,
      validator: (value: SUPPORTED_CHART_THEMES): boolean => Object.values(SUPPORTED_CHART_THEMES).includes(value)
    }
  }
})
