import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, mergeProps as _mergeProps, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e6b731b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "check-in-textarea__icons" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSpacer = _resolveComponent("AppSpacer")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["check-in-textarea", { 'check-in-textarea--resizeable': _ctx.resizeable }])
  }, [
    _createElementVNode("label", { for: _ctx.id }, _toDisplayString(_ctx.label), 9, _hoisted_1),
    _withDirectives(_createElementVNode("textarea", _mergeProps(_ctx.$attrs, {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus', $event.target.value))),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur', $event.target.value)))
    }), null, 16), [
      [_vModelText, _ctx.inputValue]
    ]),
    _createVNode(_component_AppSpacer, {
      type: "simple",
      class: "check-in-textarea__spacer"
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showPlusIcon)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "check-in-textarea__plus-icon",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('user-action:add')))
          }, [
            _createVNode(_component_AppIcon, { name: "plus" })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: "check-in-textarea__at-icon",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('user-action:tag')))
      }, [
        _createVNode(_component_AppIcon, { name: "at-sign" })
      ]),
      _createVNode(_component_AppButton, {
        size: "small",
        class: _normalizeClass({ 'check-in-textarea__button-submit-with-icon' : !_ctx.buttonText }),
        icon: !_ctx.buttonText ? 'vector' : '',
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('user-action:post')))
      }, {
        default: _withCtx(() => [
          (_ctx.buttonText)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.buttonText), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class", "icon"])
    ])
  ], 2))
}