<template>
  <section :id="id" class="design-system-item">
    <p class="block h2">{{ title }}</p>

    <div class="grid align-items-center">
      <slot />
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DesignSystemItem',
  props: {
    id: {
      type: String,
      required: true,
      default: ''
    },
    title: {
      type: String,
      required: true,
      default: ''
    }
  }
})
</script>

<style scoped>
.design-system-item {
  margin-bottom: 32px;
}
</style>
