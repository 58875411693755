
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from '@/store'
import SocialConnectCard from '@/components/feature/daily-sync/SocialConnectCard.vue'
import { DateTime } from 'luxon'
import { SocialConnect } from '@/types/socialConnect'

export default defineComponent({
  name: 'SocialConnect',
  components: {
    SocialConnectCard
  },
  setup() {
    const store = useStore()
    const dayBeingViewedOffset = ref(0)
    const isLoading = ref(true)
    const dayBeingViewed = computed<string>(() => DateTime.fromISO('2000-01-01').plus({ days: dayBeingViewedOffset.value }).toISODate())
    const socialConnectForDayBeingViewed = computed<SocialConnect | undefined>(() => store.getters['socialConnect/getSocialConnects'][dayBeingViewed.value])

    watch(dayBeingViewed, async (e) => {
      if (e) {
        const teamId = store.getters['user/getActiveMembership']?.teamId
        await store.dispatch('socialConnect/getSocialConnect', { teamId, startDate: e, days: 0 })
        isLoading.value = false
      }
    }, { immediate: true })
    return {
      isLoading,
      dayBeingViewedOffset,
      socialConnectForDayBeingViewed
    }
  }
})
