
import { computed, defineComponent } from 'vue'
import AppSpacer from '@common/AppSpacer.vue'

export default defineComponent({
  name: 'CheckInTextarea',
  components: {
    AppSpacer
  },
  inheritAttrs: false,
  emits: ['update:modelValue', 'focus', 'blur', 'user-action:add', 'user-action:tag', 'user-action:post'],
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    resizeable: {
      type: Boolean,
      required: false,
      default: true
    },
    modelValue: {
      type: String,
      required: false,
      default: ''
    },
    showPlusIcon: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      required: false
    }
  },
  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return {
      inputValue
    }
  }
})
